import './SectionHeader.scss';

export default function SectionHeader(props:any){
    return(
        <>
        <div className='sectionHeader'>
            <img src={'/icons/' + props.icon + '.png'} alt={props.alt} height={'40px'} />
            <h3>{props.title}</h3>
        </div>
        <hr className='sectionHeader__divider'/>
        </>
    );
}