import './InfoSection.scss'

export default function InfoSection(props:any){
    return(
        <div className='infoSection'>
            <div className="iconContainer">
                <img src={'/icons/' + props.icon + '.png'} alt={props.alt} height={'40px'} />
            </div>
            <p>{props.title}</p>
        </div>
    );
}