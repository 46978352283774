import "./RightSideContent.scss"
import SkillBar from "./SkillBar/SkillBar"
import SectionHeader from "./SectionHeader/SectionHeader"
import InfoSection from "./InfoSection/InfoSection"
import Spacer from "./Spacer/Spacer"
import LangaugeBar from "./LangaugeBar/LangaugeBar"

export default function RightSideContent(){
    return(
        <div className="rightSideContent">
            <div className="rightSideContent__itemContainer">
                <img className="avatar" src="/avatar.jpg" alt="Lasse Kristiansen" />
            </div>
            <div className="rightSideContent__itemContainer">
                <SectionHeader icon = {'address-card-regular'} alt = {'Address Card'} title = {'Kontakt'} />
                <InfoSection icon = {'envelope'} alt = {'Envelope'} title = {'LasseLaFlair@gmail.com'} />
                <InfoSection icon = {'mobile-alt'} alt = {'mobile'} title = {'+45 53811622'} />
                <InfoSection icon = {'map-marker-alt'} alt = {'map-marker'} title = {'8800 Viborg, DK'} />
                <InfoSection icon = {'globe-solid'} alt = {'globe'} title = {'LasseLaFlair.dk'} />
                <Spacer />
            </div>
            <div className="rightSideContent__itemContainer">
                <SectionHeader icon = {'lightbulb-regular'} alt = {'Lightbulb'} title = {'Top Skills'} />
                <SkillBar skillName = {"3D Modeling"} skillLevel = {'50%'} />
                <SkillBar skillName = {"Illustrator"} skillLevel = {'70%'} />
                <SkillBar skillName = {"InDesign"} skillLevel = {'80%'} />
                <SkillBar skillName = {"IT"} skillLevel = {'95%'} />
                <SkillBar skillName = {"Maya"} skillLevel = {'60%'} />
                <Spacer />
            </div>
            <div className="rightSideContent__itemContainer">
                <SectionHeader icon = {'Code-language-solid'} alt = {'Code-language-solid'} title = {'Sprog'} />
                <SkillBar skillName = {"HTML/CSS"} skillLevel = {'90%'} />
                <SkillBar skillName = {"JavaScript"} skillLevel = {'70%'} />
                <SkillBar skillName = {"C#"} skillLevel = {'40%'} />
                <SkillBar skillName = {"Python"} skillLevel = {'80%'} />
                <SkillBar skillName = {"PHP"} skillLevel = {'20%'} />
                <Spacer />
            </div>
            <div className="rightSideContent__itemContainer">
                <SectionHeader icon = {'language-solid'} alt = {'language-solid'} title = {'Verbale Sprog'} />
                <LangaugeBar langaugeName = {'Danish'} langaugeProficiencyLevel = {'Native'} proficiencyLevel = {'100%'} />
                <LangaugeBar langaugeName = {'English'} langaugeProficiencyLevel = {'EF SET - C1 Level'} proficiencyLevel = {'97%'} />
                <LangaugeBar langaugeName = {'Japanese'} langaugeProficiencyLevel = {'Beginner'} proficiencyLevel = {'10%'} />
                <Spacer />
            </div>
        </div>
    )
}