import './LangaugeBar.scss'

export default function LangaugeBar(props:any){
    return(
        <div className="langaugeBar">
            <div className="langaugeProficiencyBar">
                <div className="langaugeProficiencyLevel">
                    <h4>{props.langaugeName}</  h4>
                    <h5>{props.langaugeProficiencyLevel}</h5>
                </div>
                <div className="bar">
                    <div className="barForeground" style={{ width : props.proficiencyLevel }}> </div>
                    <div className="barBackground"> </div>
                </div>
            </div>
        </div>
    );
}