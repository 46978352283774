import "./LeftSideContent.scss"

export default function LeftSideContent(){
    return(
        <div className="leftSideContent">
            <h3>Erhvervserfaring</h3>
            <div className="itemBlock">
                <h4>LaFlair Media, <span className="lightFont">2019 - Nu</span></h4>
                <h5>Freelance Graphics/Web generalist</h5>
                <ul>
                    <li>Logo design - NovaTag.</li>
                    <li>Logo design - HAJ Boghulderiservice.</li>
                    <li>Vedligehuldelse af Wordpress, Simplero og Shopify side.</li>
                </ul>
            </div>
            <div className="itemBlock">
                <h4>Amager Electronic, <span className="lightFont">2017 - 2019</span></h4>
                <h5>Salgsassistent / Grafisk Designer / Teknikker</h5>
                <ul>
                    <li>Computer reparationer og Ekspedition af kunder.</li>
                    <li>Designe reklamer til digital distribution og print.</li>
                </ul>
            </div>
            <div className="itemBlock">
                <h4>Sulrød Gymnastikforening, <span className="lightFont">2015 - 2019</span></h4>
                <h5>Parkour Instruktør / Huldleder</h5>
                <ul>
                    <li>Undervist børn og unge.</li>
                </ul>
            </div>
            <div className="itemBlock">
                <h4>Erhvervsskolen Det Musiske Kompagni, <span className="lightFont">2015</span></h4>
                <h5>Freelance Graphics/Web generalist</h5>
                <ul>
                    <li>Undervist medstuderende i blandt andet Photoshop og Maya samt basis koncepter i programmering.</li>
                    <li>Medhjælper til kursus i Maya (ZBC Ringsted).</li>
                </ul>
            </div>
            <br />
            <h3>Uddannelse</h3>
            <div className="itemBlock">
                <h4>Mercantec, <span className="lightFont">2020 - 2022</span></h4>
                <h5>IT-Support/Data-Tekniker med speciale i infrastruktur</h5>
                <ul>
                    <li>Engelsk C niveau (12 / A).</li>
                    <li>Matematik D niveau (12 / A).</li>
                </ul>
            </div>
            <div className="itemBlock">
                <h4>RTS Center for medie & kommunikation, <span className="lightFont">2018</span></h4>
                <h5>Digital Media - Gennemført GF 2</h5>
                <ul>
                    <li>Hovedfag (12 / A).</li>
                    <li>Design C niveau (12 / A).</li>
                </ul>
            </div>
            <div className="itemBlock">
                <h4>Erhvervsskolen Det Musiske Kompagni, <span className="lightFont">2013 - 2017</span></h4>
                <h5>Spiludvikling & Lydteknik</h5>
                <ul>
                    <li>Spiludvikling: Konceptudvikling og Prototyping.</li>
                    <li>Lydteknik: Studie optagelse, PA opsætning, Foley.</li>
                </ul>
            </div>
            <div className="itemBlock">
                <h4>AAU - Aalborg Universitet Copenhagen, <span className="lightFont">2015</span></h4>
                <h5>Praktik 1 semester - Medialogi</h5>
            </div>
            <br />
            <h3>Personlige kvalifikationer</h3>
            <ul>
                <li>Glad, udadvendt, og imødekommende.</li>
                <li>Detaljeorienteret.</li>
                <li>Fremragende problemløsningsfærdigheder.</li>
                <li>Fremragende teknulogisk forståelse.</li>
                <li>Undervisnings erfaring indenfor 3D modellering, Programmering og Photoshop.</li>
            </ul>
        </div>
    )
}