import React from "react"
import "./SkillBar.scss"

export default function SkillBar (props:any){
    return(
        <div className="skillBar">
            <h4>{props.skillName}</h4>
            <div className="bar">
                <div className="barForeground" style={{ width : props.skillLevel }}> </div>
                <div className="barBackground"> </div>
            </div>
        </div>
    );
}