import "./MainContent.scss"
import RightSideContent from "../RightSideContent/RightSideContent"
import LeftSideContent from "../LeftSideContent/LeftSideContent"


export default function MainContent(){
    return(
        <div className="mainContent">
            <LeftSideContent />
            <RightSideContent />
        </div>
    )
}